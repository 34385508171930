// ALL Apis 
//----------------------Login resigter
export const login = { api: "auth/local", method: "POST" }


export const getPatientList = { api: "patients?pagination%5BwithCount%5D=true&pagination%5BpageSize%5D=100&populate=answer", method: "GET" }
export const getQuestionList = { api: "questions", method: "GET" }

export const getAnswersList = {
    url: 'answers/',
    params: '',
    status: true,
    get api() {
        return this.url + this.params
    },
    set addOffset(params) {
        this.params = params
    },
    method: "GET"
}

export const updateAnswers = {
    url: 'answers/',
    params: '',
    status: true,
    get api() {
        return this.url + this.params
    },
    set addOffset(params) {
        this.params = params
    },
    method: "PUT"
}

// {{baseUrl}}/answers/:id
