// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

// ** Handle User Login
export const handleLogin = data => {


  return dispatch => {
    dispatch({
      type: 'LOGIN',
      data: data?.user,
      config,
      [config.storageTokenKeyName]: data?.jwt,
    })

    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify(data?.user))
    localStorage.setItem(config.storageTokenKeyName, JSON.stringify(data.jwt))
    localStorage.setItem("AuthToken", JSON.stringify(data.jwt))
    localStorage.setItem("TokenExpired", false)
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return dispatch => {
    dispatch({ type: 'LOGOUT', [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('AuthToken')
    localStorage.removeItem('TokenExpired')
    localStorage.removeItem(config.storageTokenKeyName)

  }
}


export const handleLoginPatient = data => {

  console.log({ data });

  return dispatch => {
    dispatch({
      type: 'LOGIN_PATIENT',
      data: data?.user,
      config,
      [config.storageTokenKeyName]: data?.jwt,
    })

    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('patientData', JSON.stringify(data?.user))
    localStorage.setItem(config.storageTokenKeyName, JSON.stringify(data.jwt))
    localStorage.setItem("patientAuthToken", JSON.stringify(data.jwt))
    localStorage.setItem("patientTokenExpired", false)
  }
}

// ** Handle User Logout
export const handleLogoutPatient = () => {
  return dispatch => {
    dispatch({ type: 'LOGOUT_PATIENT', [config?.storageTokenKeyName]: null, [config?.storageRefreshTokenKeyName]: null })

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('patientData')
    localStorage.removeItem('patientAuthToken')
    localStorage.removeItem('patientTokenExpired')
    localStorage.removeItem(config.storageTokenKeyName)

  }
}
