// ** Initial State
const initialState = {
    AnswerList: [],
    loading_Answer: false,
    error_Answer: ''
}
const answerReducer = (state = initialState, action) => {
    switch (action.type) {

        // All Patients list
        case 'Answer_LOADING': {
            return {
                ...state,
                loading_Answer: true,
                error_Answer: ''
            }
        }
        case 'Answer_SUCCESS': {
            return {
                ...state,
                AnswerList: [...state.AnswerList, action.data],
                loading_Answer: false
            }
        }
        case 'Answer_SET': {
            return {
                ...state,
                AnswerList: action.answer,
                loading_Answer: false
            }
        }
        case 'Answer_ERROR': {
            return {
                ...state,
                loading_Answer: false,
                error_Answer: action.error
            }
        }


        default:
            return state
    }
}

export default answerReducer
