import { api } from "../../services/api"
import { getPatientList }  from "../../services/apiVariables" 


export function PatientListGet(offset) {
    return (dispatch) => { 
     dispatch({ type: 'Patient_LOADING' }) 
    api(getPatientList).then(response => response.json())  // convert to json
    .then(
           data => dispatch({ type: 'Patient_SUCCESS', data }),
           error => dispatch({ type:'Patient_ERROR', error: error.message || 'Unexpected Error!!!' })
       ) 
    }
}

