// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

const tokenGet = localStorage.getItem('AuthToken')
if (tokenGet === null) { localStorage.setItem("AuthToken", '') }

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}
const paths = location?.pathname?.split('/')
if (tokenGet !== '' && paths[1] !== 'login') {
  const decodedJwt = parseJwt(tokenGet)
  if (decodedJwt?.exp * 1000 < Date.now()) {
    localStorage?.setItem("AuthToken", '')
    localStorage?.setItem("TokenExpired", true)
    location?.replace('/')
  }
}

import { PatientListGet } from '@store/actions/admin'



if (localStorage?.getItem('accessToken') && localStorage?.getItem('userData')) {
  store.dispatch(PatientListGet())

  let auth = {
    jwt: JSON.parse(localStorage.getItem('accessToken')),
    user: JSON.parse(localStorage.getItem('userData'))
  }

  // console.log({ auth });

  store.dispatch(handleLogin(auth))
} else if (localStorage?.getItem('patientAuthToken') && localStorage?.getItem('patientData')) {


  let auth = {
    jwt: JSON.parse(localStorage.getItem('patientAuthToken')),
    user: JSON.parse(localStorage.getItem('patientData'))
  }

  // console.log({ auth });

  store.dispatch(handleLoginPatient(auth))
}







// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'
import '@src/custom.css'
// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// import "@node_modules/video-react/dist/video-react.css"; // import css


// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { handleLogin, handleLoginPatient } from './redux/actions/auth'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
