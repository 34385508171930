import axios from "axios"

export const BaseURL = 'https://api.holistichomeo.in';
// export const BaseURL = 'http://localhost:1337';

// function validURL(str) {
//   var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
//     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
//     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
//     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
//     '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
//     '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
//   return !!pattern.test(str);
// }

function validateURL(link) {
  if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
    return true
  }
  else {
    return false
  }
}


export const videoWithBaseUrl = (url) => {
  return validateURL(url) ? url : `https://${url}`
}

export const axiosInstance = axios.create({
  baseURL: `${BaseURL}/api`,
})


export const api = async function ({ method, api, body, formData }) {
  const tokenGet = localStorage.getItem('AuthToken')
  const tokenPatientGet = localStorage.getItem('patientAuthToken')
  const token = tokenGet ? tokenGet?.replace(/['"]+/g, '') : tokenPatientGet?.replace(/['"]+/g, '')
  const result = await fetch(`${BaseURL}/api/${api}`, {
    method,
    headers: formData ? {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    } : token ? {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
      // 'Cache-Control': 'no-cache, no-store, must-revalidate',
      // Pragma: 'no-cache',
      // Expires: 0
    } : {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    ...(body ? {
      body: JSON.stringify(body)
    } : formData ? { body: formData } : "")
  })
  return await result
}

// export const apiFile = async function ({api, formData}) {
//   const tokenGet = localStorage.getItem('AuthToken')
//   const token = tokenGet.replace(/['"]+/g, '')
//   const result = await fetch(`${BaseURL}/api/${api}`, {
//     method:"POST",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Cache-Control': 'no-cache, no-store, must-revalidate',
//       Pragma: 'no-cache',
//       Expires: 0
//     },
//     body:formData
//   })
//   return await result
// }