// ** Initial State
const initialState = {

  PatientList:[],
  loading_Patient:false,
  error_Patient: ''
}
const adminReducer = (state = initialState, action) => {
  switch (action.type) {

    // All Patients list
       case 'Patient_LOADING': {
           return {
               ...state,
               loading_Patient: true,
               error_Patient:''
           }
       }
       case 'Patient_SUCCESS': {
           return {
               ...state,
               PatientList: action.data,
               loading_Patient: false
           }
       }
       case 'Patient_ERROR': {
           return {
               ...state,
               loading_Patient: false,
               error_Patient: action.error
           }
       }
       
    
    default:
      return state
  }
}

export default adminReducer
